.package-selector-container{
    width: 200px;
    flex-direction: column;
 
}
.package-selector-dropdown{
    border: none;
    height: 30px;
    padding-left: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(248, 249, 250, 1);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    width: 200px;
    overflow-y: auto;
    overflow-x: hidden; 
}
.package-selector-dropdown:focus{

    outline: none;
}

input:focus{

  outline: 1px;
}
.package-selector-dropdown-options{
    position: absolute;
    background-color: white;
    width: 200px;
    z-index: 2000;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    max-height: 250px;
    padding-bottom: 5px;
     overflow-y: auto; 
     overflow-x: hidden;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.package-selector-dropdown-icon{
    padding-right: 10px;
}
.option-label{
  
    font-size: 16px;
}

/* Hide the browser's default checkbox */
.package-selector-dropdown-options input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

.package-option-item{
  width: 200px;
  text-align: left;
  display: block;
  position: relative;
  padding-left: 10px;
  border: none;

  padding-bottom: 5px;
  color: black;
  background-color: white;
}
  .package-option-selected{
    width: 200px;
    text-align: left;
    display: block;
    position: relative;
    padding-left: 10px;
    border: none;
    padding-bottom: 5px;
    color: white;
    background-color: var(--heritage);
  }